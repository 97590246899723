import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';

export default makeStyles<Theme>((theme) =>
  createStyles({
    youtube: {
      '& iframe': {
        display: 'block',
        height: '100%',
        overflow: 'hidden',
        position: 'absolute',
        width: '100%',
      },
      aspectRatio: 1.785,
      display: 'block',
      overflow: 'hidden',
      position: 'relative',
      width: '100%',
    },
  })
);
