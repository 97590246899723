import classNames from 'classnames';
import React, { useMemo } from 'react';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';

import { useScreenSize } from '@hooks/useScreenSize';
import { defaultComponent } from './constants';
import { IHomepageYoutubeBannerComponent, IHomepageYoutubeBannerProps } from './interfaces';

import YoutubeStyles from './styles';

const HomepageYoutubeBanner: React.FunctionComponent<IHomepageYoutubeBannerProps> = (props) => {
  const component: IHomepageYoutubeBannerComponent = { ...defaultComponent, ...props.component };

  const classes = YoutubeStyles();
  const getId = useMemo(() => component?.url?.split('/').reverse()[0], [component.url]);

  const { isMobile } = useScreenSize();
  const imgQuality = isMobile ? 'hqdefault' : 'maxresdefault';

  return (
    <div className={classNames(classes.youtube, 'youtbe-banner', props.className)}>
      <LiteYouTubeEmbed
        id={getId}
        title="Embedded youtube"
        poster={imgQuality}
      />
    </div>
  );
};

export default HomepageYoutubeBanner;
